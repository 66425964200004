import { tv } from 'tailwind-variants'

export const breadcrumbStyle = {
  alpha: tv({
    slots: {
      list: 'flex flex-row items-center flex-wrap',
      activeBreadcrumb:
        'text-brand-secondary-800 hover:text-brand-secondary-800',
    },
  }),
  bravo: tv({
    slots: {
      list: 'flex flex-row items-center flex-wrap',
      activeBreadcrumb:
        'text-brand-secondary-800 hover:text-brand-secondary-800',
    },
  }),
  charlie: tv({
    slots: {
      list: 'flex flex-row items-center flex-wrap',
      activeBreadcrumb:
        'font-link14 text-brand-gray-900 hover:text-brand-gray-900',
    },
  }),
}
