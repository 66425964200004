<script setup lang="ts">
import { AuthType } from '@CPEnums'

const localePath = useLocalePath()
const route = useRoute()
const { strategy } = useI18n()

const numberOfParams = route.path
  .split('/')
  .slice(strategy === 'prefix' ? 2 : 1).length
</script>

<template>
  <div
    v-if="$auth.user.value?.authType === AuthType.booking && numberOfParams > 3"
    class="flex flex-row items-center"
  >
    <CPLink class="cursor-pointer" @click="$router.back()">
      <Icon
        class="-mr-1"
        size="24"
        name="material-symbols-light:chevron-left-rounded"
      />

      {{ $t('cp.breadcrumb.back') }}
    </CPLink>
    <CPDivider class="h-3 mx-4" />
  </div>
  <div v-if="$auth.user.value?.authType === AuthType.user">
    <CPLink
      v-if="numberOfParams < 2"
      class="hidden xl:block text-brand-secondary-800 hover:text-brand-secondary-900"
    >
      {{ $t('cp.breadcrumb.bookings') }}
    </CPLink>

    <li
      v-if="numberOfParams >= 2"
      class="-ml-2 flex flex-row items-center xl:hidden"
    >
      <CPLink
        class="cursor-pointer"
        @click="
          () => {
            numberOfParams >= 3
              ? $router.back()
              : navigateTo(localePath('profile'))
          }
        "
      >
        <Icon size="24" name="material-symbols-light:chevron-left-rounded" />

        {{
          numberOfParams >= 3
            ? $t('cp.breadcrumb.back')
            : $t('cp.breadcrumb.menu')
        }}
      </CPLink>

      <CPDivider class="h-3 mx-4" />
    </li>

    <li
      v-if="numberOfParams >= 3"
      class="-ml-2 hidden xl:flex xl:flex-row xl:items-center"
    >
      <CPLink class="cursor-pointer" @click="$router.back">
        <Icon size="24" name="material-symbols-light:chevron-left-rounded" />

        <p>{{ $t('cp.breadcrumb.back') }}</p>
      </CPLink>

      <CPDivider class="h-3 mx-4" />
    </li>
  </div>
</template>
