<script lang="ts" setup>
import { tv } from 'tailwind-variants'
import { sideBarStyling } from './sideBarStyles'

const theme = useRuntimeConfig().public.theme as keyof typeof sideBarStyling
const themeStyling = sideBarStyling[theme]
const { $auth } = useNuxtApp()

const links = useCPLinks()

const styling = tv({
  extend: themeStyling,
})

const { wrapper, heading, textWrapper, menuContainer, text } = styling()
</script>

<template>
  <CPCard :class="wrapper()">
    <div :class="textWrapper()">
      <div :class="heading()">{{ $auth.user.value?.firstName }}</div>
      <div :class="text()">{{ $auth.user.value?.email }}</div>
    </div>
    <div :class="menuContainer()">
      <CPSideBarItem
        v-for="(link, index) in links"
        :key="index"
        :heading="link.heading"
        :text="link.text"
        :icon="link.icon"
        :link="link.link"
      />
    </div>
  </CPCard>
</template>
