export const sideBarStyling = {
  alpha: {
    slots: {
      text: '',
      wrapper: 'pt-md pb-xl',
      textWrapper: 'pl-sm pb-md text-brand-secondary-800',
      heading: 'font-bold  font-heading leading-7 text-xl',
      menuContainer: 'flex flex-col border-brand-gray-300 border-y',
    },
  },
  bravo: {
    slots: {
      text: '',
      wrapper: 'pt-md pb-xl',
      textWrapper: 'pl-sm pb-md text-brand-secondary-800',
      heading: 'font-bold  font-heading leading-7 text-xl',
      menuContainer: 'flex flex-col border-brand-gray-300 border-y',
    },
  },
  charlie: {
    slots: {
      text: 'font-p12',
      wrapper: 'pt-md pb-xl',
      textWrapper: 'pl-sm pb-xs text-brand-blue-900',
      heading: 'font-h3',
      menuContainer:
        'flex flex-col [&>*:first-child]:border-brand-gray-300 [&>*:first-child]:border-t [&>*:last-child]:border-b  last:border-b',
    },
  },
}
