<script setup lang="ts">
import { breadcrumbStyle } from './breadcrumbStyles'
const theme = useRuntimeConfig().public.theme as keyof typeof breadcrumbStyle
const ui = breadcrumbStyle[theme]

const { breadcrumbs } = useCPBreadCrumbs()

const { activeBreadcrumb, list } = ui()
</script>

<template>
  <nav aria-label="Breadcrumb">
    <ol role="list" :class="list()">
      <CPBreadcrumbBack />

      <li
        v-for="(item, index) in breadcrumbs?.filter(
          (item) => item.link && item.name
        )"
        :key="index"
      >
        <CPLink
          :to="item.link"
          :class="[{ [activeBreadcrumb()]: item.active }]"
        >
          {{ item.name }}
          <Icon
            v-if="index !== breadcrumbs.length - 1"
            size="24"
            name="material-symbols-light:chevron-right-rounded"
          />
        </CPLink>
      </li>
    </ol>
  </nav>
  <CPDivider class="mt-2 hidden xl:block" />
  <CPDivider v-if="breadcrumbs.length" class="mt-2 xl:hidden" />
</template>
