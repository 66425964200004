export const sideBarStyling = {
  alpha: {
    slots: {
      wrapper:
        'flex flex-row items-center justify-between cursor-pointer py-xs px-xs border-y group hover:border-y hover:border-brand-secondary-700 transition-all ',
      iconWrapper: 'rounded-lg p-xxs flex-shrink-0 ml-xs',
      iconStyling:
        'flex-shrink-0 translate-y-[3px] group-hover:text-brand-secondary-700',
      headingStyling:
        'text-lg font-body leading-[28px] group-hover:text-brand-secondary-700',
      chevronStyling: 'flex-shrink-0 group-hover:text-brand-secondary-700',
    },
    variants: {
      active: {
        true: {
          wrapper: 'bg-brand-gray-200 border-brand-secondary-700',
          iconWrapper: 'bg-brand-gray-200',
          iconStyling: 'text-brand-secondary-700',
          headingStyling: 'text-brand-secondary-700',
          chevronStyling: 'text-brand-secondary-700',
        },
        false: {
          wrapper: 'bg-brand-white border-brand-gray-300',
          iconWrapper: 'bg-brand-secondary-100',
          iconStyling: 'text-brand-secondary-400',
          headingStyling: 'text-brand-secondary-400',
          chevronStyling: 'text-brand-secondary-400',
        },
      },
    },
  },
  bravo: {
    slots: {
      wrapper:
        'flex flex-row items-center justify-between cursor-pointer py-xs px-xs border-y group hover:border-y hover:border-brand-secondary-700 transition-all ',
      iconWrapper: 'rounded-lg p-xxs flex-shrink-0 ml-xs',
      iconStyling:
        'flex-shrink-0 translate-y-[3px] group-hover:text-brand-secondary-700',
      headingStyling:
        'text-lg font-body leading-[28px] group-hover:text-brand-secondary-700',
      chevronStyling: 'flex-shrink-0 group-hover:text-brand-secondary-700',
    },
    variants: {
      active: {
        true: {
          wrapper: 'bg-brand-gray-200 border-brand-secondary-700',
          iconWrapper: 'bg-brand-gray-200',
          iconStyling: 'text-brand-secondary-700',
          headingStyling: 'text-brand-secondary-700',
          chevronStyling: 'text-brand-secondary-700',
        },
        false: {
          wrapper: 'bg-brand-white border-brand-gray-300',
          iconWrapper: 'bg-brand-secondary-100',
          iconStyling: 'text-brand-secondary-400',
          headingStyling: 'text-brand-secondary-400',
          chevronStyling: 'text-brand-secondary-400',
        },
      },
    },
  },
  charlie: {
    slots: {
      wrapper:
        'flex flex-row items-center justify-between cursor-pointer py-xs px-xs group hover:border-brand-blue-700 transition-all',
      iconWrapper: 'rounded-lg p-xxs flex-shrink-0 ml-xs',
      iconStyling:
        'flex-shrink-0 translate-y-[3px] group-hover:text-brand-blue-700',
      headingStyling:
        'text-lg font-body leading-[28px] group-hover:text-brand-blue-700',
      chevronStyling: 'flex-shrink-0 group-hover:text-brand-blue-700',
    },
    variants: {
      active: {
        true: {
          wrapper: 'bg-brand-gray-200 border-y !border-brand-blue-700',
          iconWrapper: 'bg-brand-gray-200',
          iconStyling: 'text-brand-blue-700',
          headingStyling: 'text-brand-blue-700',
          chevronStyling: 'text-brand-blue-700',
        },
        false: {
          wrapper: 'bg-brand-white border-brand-gray-300',
          iconWrapper: 'bg-brand-blue-100',
          iconStyling: 'text-brand-blue-500',
          headingStyling: 'text-brand-blue-500',
          chevronStyling: 'text-brand-blue-500',
        },
      },
    },
  },
}
