<script lang="ts" setup>
import { tv } from 'tailwind-variants'
import { sideBarStyling } from './itemStyles'
import type { MenuLinks } from '~/types/CPMenu'

const props = defineProps<MenuLinks>()
const localePath = useLocalePath()
const routeBaseName = useRouteBaseName()
const route = useRoute()

const islinkActive = computed(() => {
  const profileBaseName = 'profile'
  const bookingBaseName = 'profile-bookings'
  const currentRouteBaseName = routeBaseName(route)
  const isCurrentLinkBookings = props.link === bookingBaseName
  const isCurrentRouteBookings = currentRouteBaseName === bookingBaseName
  const isCurrentRouteProfile = currentRouteBaseName === profileBaseName

  if (isCurrentLinkBookings && isCurrentRouteBookings) {
    return true
  }

  if (isCurrentLinkBookings && isCurrentRouteProfile) {
    return true
  }

  if (currentRouteBaseName?.includes(props.link)) {
    return true
  }
})

const theme = useRuntimeConfig().public.theme as keyof typeof sideBarStyling
const themeStyling = sideBarStyling[theme]

const styling = tv({
  extend: themeStyling,
})

const { wrapper, iconWrapper, iconStyling, headingStyling, chevronStyling } =
  styling({
    active: islinkActive.value,
  })
</script>

<template>
  <NuxtLink :to="localePath(link)" :class="wrapper()">
    <div class="flex items-center gap-md">
      <div :class="iconWrapper()">
        <Icon :class="iconStyling()" size="40" :name="icon" />
      </div>
      <div :class="headingStyling()">
        {{ heading }}
      </div>
    </div>
    <Icon
      :class="chevronStyling()"
      size="46"
      name="material-symbols-light:chevron-right-rounded"
    />
  </NuxtLink>
</template>
