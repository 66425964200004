import { AuthType } from '@CPEnums'

export const useCPBreadCrumbs = () => {
  const { t } = useI18n()
  const route = useRoute()
  const localePath = useLocalePath()
  const routeBaseName = useRouteBaseName()
  const slugs = routeBaseName(route)?.split('-').slice(1)
  const localeRoute = useLocaleRoute()
  const { $auth } = useNuxtApp()

  let previousSlug = ''

  const breadcrumbs = slugs?.map((slug) => {
    const isParam = !!route.params[slug]

    const routePathName = `profile${previousSlug}-${slug}`
    const routeMiddlewares = localeRoute(routePathName)?.meta.middleware

    if (
      Array.isArray(routeMiddlewares) &&
      routeMiddlewares.includes('user-type-restriction') &&
      $auth.user.value?.authType === AuthType.booking
    ) {
      previousSlug = previousSlug + `-${slug}`
      return {
        name: '',
        link: '',
        active: false,
      }
    }

    const breadcrumb = {
      name: isParam
        ? (route.params[slug] as string)
        : t(`cp.breadcrumb.${slug}`),
      link: localePath({
        name: routePathName,
        params: {
          [slug]: isParam ? route.params[slug] : undefined,
        },
      }),
      active: routePathName === routeBaseName(route),
    }

    previousSlug = previousSlug + `-${slug}`

    return breadcrumb
  })

  return {
    breadcrumbs: breadcrumbs || [],
  }
}
